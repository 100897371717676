import axios from 'axios';
import React from 'react'
import { Modal } from 'react-bootstrap';
import noImg from '../img/no image.png';
import { MdVerified, MdDangerous } from "react-icons/md";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import LoadingScreen from './LoadingScreen/LoadingScreen';

const ModalCompanyList = ({show, handleClose, dataById}) => {
    const [loading, setLoading] = React.useState(false);

    // console.log("jfj", dataById)

    const handleToggle = async () => {
        try {
            setLoading(true);
            const boolData = !dataById?.isProductVerified

            await axios.post(`https://onesourceadminservice.gs1ng.org/v1/CompanyListing/VerifyProductInfo?registrationID=${dataById?.registrationID}&gtin=${dataById?.gtin}&approvalStatus=${boolData}`)
            .then((res) => {
                console.log(res.data)
                setLoading(false);
                window.location.reload();
              })
              .catch((error) => {
                console.error("Error fetching records:", error);
                // console.error("Error fetching records:", error.request.response);
                setLoading(false);
              });

        } catch (error) {
            console.error("Error toggling switch:", error);
        }
    };
    
    
  return (
    <div>
        {loading? 
            <LoadingScreen /> : 
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Location Details</Modal.Title>
                </Modal.Header>
                <div className="d-flex align-item-center justify-content-around">
                    {dataById?.imageURL == null ? 
                        <img className="img-fluid" alt='' src={noImg} style={{height: "200px"}} /> :
                        <img className="img-fluid" alt='' src={`https://gs1ng.org/${dataById?.imageURL}`} style={{height: "200px"}} /> 
                    }
                </div>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span><b>GTIN:</b> {dataById?.gtin}</span>
                                </li>
                                <li className="list-group-item">
                                    <span><b>Product Name:</b> {dataById?.productName}</span>
                                </li>
                                <li className="list-group-item">
                                    <span><b>Product Description:</b> {dataById?.productDescription}</span>
                                </li>
                                <li className="list-group-item">
                                    <span><b>Brand Name:</b> {dataById?.brandName}</span>
                                </li>
                                <li className="list-group-item">
                                    <span><b>Packaging Type:</b> {dataById?.packagingType}</span>
                                </li>
                                <li className="list-group-item">
                                    <span><b>Brick Category:</b> {dataById?.brickCategory}</span>
                                </li>
                                <li className="list-group-item">
                                    <span><b>Net Weight:</b> {dataById?.netWeight}</span>
                                </li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                <div className="d-flex">
                    <div onClick={handleToggle}>
                        {dataById?.isProductVerified ? 
                            <BsToggleOn className='fs-2' /> :
                            <BsToggleOff className='fs-2' /> 
                        }
                    </div>
                    <div className="" >
                        {dataById?.isProductVerified ? <MdVerified className='text-success fs-3 mx-2' /> : <MdDangerous className='text-danger fs-3 mx-2' />}
                    </div>
                </div>

                </Modal.Footer>
            </Modal>
            }

    </div>
  )
}

export default ModalCompanyList;