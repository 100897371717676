import React from "react";

const NavBar = () => {
  return (
    <nav className="navbar" style={{background: "navy"}}>
      <div className="container">
        <span className="navbar-brand mb-0 text-white fw-bolder">Company List</span>
      </div>
    </nav>
  );
};

export default NavBar;
