import { Table, Tag } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
import NavBar from "./components/navBar";
import ModalCompanyList from "./components/modalCompantList";
import {GrView} from 'react-icons/gr';

function App() {
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [dataById, setDataById] = React.useState(null);


  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (allRoydata) => {
      // console.log("data ff", {allRoydata})
      const {registrationID, gtin} = allRoydata;
      fetchDataById(registrationID, gtin)
      setShow(true)
  };

  useEffect(() => {
    fetchRecords();
  }, [currentPage, searchKeyword]);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
    },
    {
      title: "Gtin",
      dataIndex: "gtin",
    },
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Product Description",
      dataIndex: "productDescription",
    },
    {
      title: "verification Status",
      dataIndex: "verificationStatus",
      render: (verificationStatus) => (
        <Tag color={verificationStatus ? "green" : "red"}>
          {verificationStatus ? "Verified" : "Not Verified"}
        </Tag>
      ),
    },
    {
      title: "View",
      // dataIndex: "id",
      render: (allRoydata) => (
        <>
          <GrView onClick={()=>handleShow(allRoydata)} className="fs-4 " />
        </>
      ),
    },
  ];

  const fetchDataById = async (registrationID, gtin) => {
    try {
      // console.log({registrationID, gtin})
      const res = await axios.get(`https://onesourceadminservice.gs1ng.org/v1/CompanyListing/GetProductInfo?registrationID=${registrationID}&gtin=${gtin}`);
      // console.log(res.data.returnedObject)
      setDataById(res.data.returnedObject)
    } catch (error) {
      console.log(error);
    }
};

  const fetchRecords = () => {
    setLoading(true);
    const apiUrl = `https://onesourceadminservice.gs1ng.org/v1/CompanyListing/GetCompanyDetails?StartPage=${currentPage}&PageSize=${pageSize}&SearchText=${searchKeyword}`;
    axios
      .get(apiUrl)
      .then((res) => {
        console.log(res.data.returnedObject)
        setDataSource(res.data.returnedObject.companyPrefix);
        setTotalRecords(res.data.returnedObject.totalRecords);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching records:", error);
        // console.error("Error fetching records:", error.request.response);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <NavBar />
      <ModalCompanyList handleClose={handleClose} show={show} dataById={dataById} />
      <div className="container">
        <div className=" mt-5 shadow p-5">

          <input
            type="text"
            placeholder="Search by name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            style={{ marginBottom: "1rem" }}
          />
          <div style={{ overflowX: "auto", maxWidth: "100%" }}>
            <Table
              loading={loading}
              columns={columns}
              // dataSource={dataSource}
              dataSource={dataSource? dataSource.map((item, index) => ({ ...item, key: index })) : null}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalRecords,
                onChange: handlePageChange,
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;